<template>
    <seccion-datos :titulo="facturacion" tipo="danger" @cambios="guardar(id)">
        <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                    <label>{{ $t('general.clave') }}</label>
                    <input v-model="clave_certificado" type="password" class="form-control dato" >
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label>{{ $t('general.certificadodigital') }}</label>
                    <input type="file" class="form-control dato" @change="handleFileUpload($event)">
                    
                </div>
            </div>
        </div>
        <div class="form-group">
            <div class="row">
                <div class="col-md-12">
                    <p class="text-yellow">{{ $t('general.descripcionclave') }}</p>
                </div>
                <div class="col-md-4">
                </div>
            </div>
        </div>
    </seccion-datos>
</template>
<script>
import { PwgsApi } from '../../../../services/PwgsApi';
export default {
    data() {
        return {
            empresa: '',
            ruta_certificado: '',
            clave_certificado: '',
            seleccionado: '',
            facturacion: '',
        }
    },
    methods: {

        async obtenerEmpresa() {
            const api = new PwgsApi;
            const datos = await api.get('empresa');
            this.empresa = datos.datos;
            this.$store.dispatch('cambiarCargando', true);
            this.ruta_certificado = this.empresa.ruta_certificado;
            this.clave_certificado = this.empresa.clave_certificado;
            this.$store.dispatch('cambiarCargando', false);
            this.facturacion = this.$t('general.facturacion');
        },
        async guardar(id) {
            /* put  modulos/pwgsapi/index.php/lineas-facturacion/:id   */
            const subidadatos = { ruta_certificado: this.ruta_certificado, clave_certificado: this.clave_certificado };
            const api = new PwgsApi;
            try {
                this.$store.dispatch('cambiarGuardando', true);
                await api.put('empresa', subidadatos);
                this.$store.dispatch('cambiarGuardando', false);
                this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Datos guardados correctamente', life: 2000 });
                this.obtenerEmpresa(id);
                this.submitFile();
            }
              catch (error) {
                this.$toast.add({ severity: 'error', summary: 'PiError', detail: error, life: 5000 });
            }

        },
        async submitFile(id) {
            //https://sonia.pwgs.app/modulos/pwgsapi/index.php/servicios/26100/guardar-documento
            try {
                const api = new PwgsApi;
                let formData = new FormData();
                formData.append('ficherosvisitas', this.file, this.file.name);
                await api.post('servicios/' + id + '/guardar-documento', formData);
            }
              catch (error) {
                this.$toast.add({ severity: 'error', summary: 'PiError', detail: error, life: 5000 });
            }
        },
        async handleFileUpload(event) {

            this.file = event.target.files[0];

        }
    },
    mounted() {
        this.obtenerEmpresa();
    },
    watch: {
        id() {
            this.obtenerEmpresa();
        }
    }
}
</script>